import clsx from 'clsx';
import React, { FC } from 'react';
import { Edit as EditIcon, Eye as EyeIcon, Trash as TrashIcon } from 'react-feather';
import { useHistory } from 'react-router';

import { IconButton, makeStyles, Menu, MenuItem, SvgIcon, Theme } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const ITEM_HEIGHT = 48;

interface MenuButtonProps {
  customActions?: { handler: (id: string, entity?: any) => void; icon: React.ReactElement; label?: string }[];
  viewPath?: string;
  editPath?: string;
  deleteAction?: () => void;
  itemId: string;
  item: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginLeft: 4
  },
  delete: {
    color: theme.palette.error.main
  }
}));

const MenuButton: FC<MenuButtonProps> = ({ viewPath, customActions, editPath, deleteAction, itemId, item }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewActionHandler = () => {
    history.push(viewPath);
    handleClose();
  };

  const customAction = (fn) => () => {
    fn(itemId, item);
    handleClose();
  };

  const editActionHandler = () => {
    history.push(editPath);
    handleClose();
  };

  const deleteActionHandler = () => {
    deleteAction();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{ marginRight: -10, marginTop: -12 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {viewPath && (
          <MenuItem key="view" onClick={viewActionHandler} className={classes.item}>
            <SvgIcon fontSize="small">
              <EyeIcon />
            </SvgIcon>
            <div className={classes.label}>Vizualizeaza</div>
          </MenuItem>
        )}
        {customActions &&
          customActions.map((ca, index) => (
            <MenuItem key={'custom' + index} onClick={customAction(ca.handler)} className={classes.item}>
              <SvgIcon fontSize="small">{ca.icon}</SvgIcon>
              <div className={classes.label}>{ca.label}</div>
            </MenuItem>
          ))}
        {editPath && (
          <MenuItem key="edit" onClick={editActionHandler} className={classes.item}>
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
            <div className={classes.label}>Editeaza</div>
          </MenuItem>
        )}
        {deleteAction && (
          <MenuItem key="delete" onClick={deleteActionHandler} className={clsx(classes.item, classes.delete)}>
            <SvgIcon fontSize="small" color="error">
              <TrashIcon />
            </SvgIcon>
            <div className={classes.label}>Delete</div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MenuButton;
