import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Edit3 } from 'react-feather';
import SignatureCanvas from 'react-signature-canvas';
import dataURLtoFile from 'src/utils/file';

import { Button, makeStyles } from '@material-ui/core';

import useAuth from '../hooks/useAuth';
import { ATTACHMENT_TYPE, UploadedFile } from '../types/file';
import { DialogAdvanced } from './DialogAdvanced';
import FileList from './FileList';

import type { FC } from 'react';
import type { Theme } from 'src/theme';
interface SignaturePadProps {
  token?: string;
  customText?: string;
  disabled?: boolean;
  onDrop: (files: any, file?: any) => void;
  values: UploadedFile[];
  onDelete?: (index: number, fileId: string) => void;
  filterType?: ATTACHMENT_TYPE;
  customAction?: (value: any) => Promise<void> | void;
  className?: string;
  type: 'general' | 'custom';
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: 'fit-content'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100'
  },
  dropZone: {
    width: '100%',
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  disabled: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    '&:hover': {
      cursor: 'default'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 60,
    margin: theme.spacing(1)
  },
  list: {
    maxHeight: 200
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  redirectText: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const labels = {
  btn: 'Adauga o semnatura'
};

const SignaturePad: FC<SignaturePadProps> = ({
  customText,
  onDrop,
  className,
  disabled,
  token,
  values = [],
  onDelete,
  type = 'general',
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const sigPad = useRef<any>({});
  const [modal, setModal] = useState<{ visible: boolean; fileId: string; index: number }>({
    visible: false,
    fileId: null,
    index: null
  });
  const [signature, setSignature] = useState<UploadedFile[]>([]);

  useEffect(() => {
    setSignature([]);
  }, [values]);
  const clear = () => sigPad?.current.clear();
  const cancel = () => {
    clear();
    setModal({ ...modal, visible: false });
  };
  const isGeneralTemplate = type === 'general';
  const trim = () => {
    const data = sigPad.current.toDataURL('image/png');
    const file = dataURLtoFile({
      dataUrl: data,
      filename: `${user?.email || 'client'}-signature.png`,
      attachmentType: ATTACHMENT_TYPE.USER_SIGNATURE
    });
    if (!isGeneralTemplate) {
      onDrop(data, !values.length ? file : null);
      return;
    }
    setSignature([file]);
    onDrop([file]);
  };

  return (
    <>
      <Button
        className={
          isGeneralTemplate
            ? clsx({
                [classes.dropZone]: true,
                [classes.disabled]: disabled
              })
            : ''
        }
        startIcon={
          isGeneralTemplate ? (
            <img alt="Adauga semnatura" className={classes.image} src="/static/images/undraw_add_file2_gvbb.svg" />
          ) : (
            <Edit3 />
          )
        }
        variant={isGeneralTemplate ? 'text' : 'contained'}
        color={isGeneralTemplate ? 'secondary' : 'primary'}
        size="small"
        onClick={() => {
          if (!isGeneralTemplate && values.length) return onDrop(values[0].uri);
          return setModal({ ...modal, visible: true });
        }}
        disabled={isGeneralTemplate ? Boolean(values.length) : false}
      >
        {customText || labels.btn}
      </Button>
      <DialogAdvanced
        visible={modal.visible}
        title="Semnatura"
        actionTitle="Adauga"
        dialogContent
        cancel={cancel}
        action={trim}
        mode="general"
        contentClassName={classes.center}
      >
        <div className={clsx(classes.root)}>
          <SignatureCanvas
            ref={(ref: any) => {
              sigPad.current = ref;
            }}
            penColor="black"
            canvasProps={{ width: 500, height: 300 }}
          />
        </div>
      </DialogAdvanced>
      {isGeneralTemplate && (
        <FileList
          onDelete={(index: number, fileId: string) => {
            setSignature([]);
            onDelete(index, fileId);
          }}
          values={values}
          attachments={signature}
          className={className}
          token={token}
          filterType={ATTACHMENT_TYPE.USER_SIGNATURE}
          {...rest}
        />
      )}
    </>
  );
};

SignaturePad.propTypes = {
  className: PropTypes.string
};

export default SignaturePad;
