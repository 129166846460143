import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ATTACHMENT_TYPE, UploadedFile } from 'src/types/file';

import { Box, Link, makeStyles, Typography } from '@material-ui/core';

import type { FC } from 'react';
import type { Theme } from 'src/theme';
import FileList from './FileList';

interface FilesDropzoneProps {
  values: UploadedFile[];
  attachments: UploadedFile[];
  className?: string;
  multiple?: boolean;
  onDrop: (files: any) => void;
  onDelete?: (index: number, fileId: string) => void;
  filterType?: ATTACHMENT_TYPE;
  noAdd?: boolean;
  noEmail?: boolean;
  token: string;
  customText?: string;
  onEmailSent: (value: any) => Promise<void> | void;
  restrictedTypes?: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  disabled: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    '&:hover': {
      cursor: 'default'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 60,
    margin: theme.spacing(1)
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 200
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  redirectText: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const labels = {
  single: { title: 'Alegeti fisierul', description: 'Incarcati fisierul prin drag-and-drop' },
  multi: { title: 'Alegeti fisierele', description: 'Incarcati fisierele prin drag-and-drop' }
};

const FilesDropzone: FC<FilesDropzoneProps> = ({
  onDrop,
  onDelete,
  multiple,
  values,
  attachments,
  className,
  token,
  ...rest
}) => {
  const classes = useStyles();
  let filteredFiles = [...values];
  if (rest.filterType) filteredFiles = filteredFiles.filter((v) => v.attachmentType === rest.filterType);
  const files = [...filteredFiles, ...attachments];
  const usedLabels = multiple ? labels.multi : labels.single;
  if (rest.customText) usedLabels.title = rest.customText;
  const disabled = !multiple && !!files.length;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple ?? false,
    disabled,
    accept: rest.restrictedTypes?.join(',') || undefined
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {!rest.noAdd && (
        <div
          className={clsx({
            [classes.dropZone]: true,
            [classes.dragActive]: isDragActive,
            [classes.disabled]: disabled
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div>
            <img alt="Select file" className={classes.image} src="/static/images/undraw_add_file2_gvbb.svg" />
          </div>
          <div>
            <Typography variant="h4">{usedLabels.title}</Typography>
            <Box mt={2}>
              <Typography color="textPrimary">
                {usedLabels.description} sau <Link underline="always">click</Link>!
              </Typography>
            </Box>
          </div>
        </div>
      )}
      <FileList
        onDelete={onDelete}
        values={values}
        attachments={attachments}
        className={className}
        token={token}
        {...rest}
      />
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
