import React, { FC, useState } from 'react';
import { Theme } from 'src/theme';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

interface DialogProps {
  visible: boolean;
  title: string;
  question: string;
  actionTitle: string;
  cancel: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  action: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  content: React.ReactNode;
  disabled?: {
    action?: boolean;
    secondaryAction?: boolean;
  };
  custom?: {
    actionIcon?: React.ReactNode;
    secondaryActionIcon?: React.ReactNode;
    secondaryActionLabel?: string;
    secondaryAction?: React.ReactNode;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  cancel: {
    color: theme.palette.primary.main
  },
  action: {
    color: theme.palette.error.main
  },
  pending: {
    width: 93,
    display: 'flex',
    justifyContent: 'center'
  }
}));

const CustomDialog: FC<DialogProps> = ({
  visible,
  title,
  question,
  actionTitle,
  cancel,
  action,
  content,
  disabled,
  custom
}) => {
  const classes = useStyles();

  const [actionPending, setActionPending] = useState(false);

  const customAction = async (event) => {
    setActionPending(true);
    await action(event);
    setActionPending(false);
  };
  return (
    <Dialog
      open={visible}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{question}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        {custom?.secondaryAction || (
          <Button onClick={cancel} startIcon={custom?.secondaryActionIcon || <CloseIcon />} className={classes.cancel}>
            {custom?.secondaryActionLabel || 'Anuleaza'}
          </Button>
        )}
        {actionPending ? (
          <Box className={classes.pending}>
            <CircularProgress size={24} className={classes.action} />
          </Box>
        ) : (
          <Button
            disabled={Boolean(disabled?.action)}
            onClick={customAction}
            startIcon={custom?.actionIcon || <DeleteIcon />}
            className={classes.action}
          >
            {actionTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
