import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import DocumentForm from 'src/components/DocumentForm';
import useGlobalModal, { MODALS } from 'src/hooks/useGlobalModal';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Category } from 'src/types/category';
import { DocumentAttributes, ProjectContract, TemplateAttribute } from 'src/types/contract';
import { LabCategory } from 'src/types/labCategory';
import { Probe, PROBE_STATUS } from 'src/types/probe';
import { ProjectProduct } from 'src/types/product';
import { Project } from 'src/types/project';
import { User } from 'src/types/user';
import { Workstation } from 'src/types/workstation';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

const REPORT_MANDATORY_FIELDS = [{ key: 'data_document', type: 'date' }];

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  noProbe: {
    fontSize: '1rem',
    marginLeft: 14
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra: { projectId: string; user: User; pvpId?: string; categoryId?: string };
}

const ProjectWorksheetCreateModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useGlobalModal();
  const [deps, setDeps] = useState<{
    categories: Category[];
    labCategories: LabCategory[];
    pvps: ProjectContract[];
    filteredPvps: ProjectContract[];
    projectProducts: ProjectProduct[];
    workstations: Workstation[];
    project: Project;
  }>({
    categories: [],
    labCategories: [],
    pvps: [],
    filteredPvps: [],
    projectProducts: [],
    workstations: [],
    project: null
  });
  const [attributes, setAttributes] = useState<DocumentAttributes>({});
  const [error, setError] = useState<{ [key: string]: boolean }>({});
  const [category, setCategory] = useState<Category>(null);
  const [workstation, setWorkstation] = useState<Workstation>(extra?.user?.workstation);
  const [template, setTemplate] = useState<ProjectContract>(null);
  const [templates, setTemplates] = useState<ProjectContract[]>([]);
  const [pvp, setPvp] = useState<ProjectContract>(null);
  const [pvps, setPvps] = useState<ProjectContract[]>([]);
  const [pvpIds, setPvpIds] = useState<string[]>([]);
  const [probeAddins, setProbeAddins] = useState<{ no: number; id: string; pno: string }[]>([]);
  const [probe, setProbe] = useState<Probe[]>([]);
  const [probeListUnreceived, setProbeListUnreceived] = useState<Probe[]>([]);
  const [probeListUntested, setProbeListUntested] = useState<Probe[]>([]);
  const [probeListReported, setProbeListReported] = useState<Probe[]>([]);
  const [probeList, setProbeList] = useState<Probe[]>([]);
  const [selectedProbes, setSelectedProbes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDependences, setLoadingDependences] = useState<boolean>(false);
  const [filters, setFilters] = useState<string[]>(['category']);

  const isValid = (): boolean => {
    const newErrors = {};
    REPORT_MANDATORY_FIELDS.forEach((el: TemplateAttribute) => {
      if (!attributes[el.key]) {
        newErrors[el.key] = true;
      }
    });
    if (!selectedProbes.length) {
      // eslint-disable-next-line dot-notation
      newErrors['probe'] = true;
    }
    if (!workstation) {
      // eslint-disable-next-line dot-notation
      newErrors['workstation'] = true;
    }
    setError(newErrors);
    return !Object.keys(newErrors)?.length;
  };

  const getSelectedProbesIds = (): string => {
    let ids = '';
    selectedProbes.forEach((pno: string) => {
      const proba: Probe | undefined = probe.find((el: Probe) => el.pno === pno);
      if (proba) {
        if (ids) ids += ',';
        ids += proba.id;
      }
    });
    return ids;
  };

  const saveHandler = async () => {
    if (!isValid()) return;
    try {
      setLoading(true);
      attributes['extra.projectId'] = extra?.projectId;
      attributes['extra.categoryId'] = category.id;
      attributes['extra.workstationId'] = workstation.id;
      attributes['extra.templateId'] = template.id;
      attributes['extra.probeIds'] = getSelectedProbesIds();
      attributes['extra.clientId'] = deps.project.client.id;
      attributes['extra.clientCompanyName'] = deps.project.client.companyName;
      if (pvpIds.length) attributes['extra.pvpIds'] = pvpIds.join(',');
      const pc = await api.projectContracts.generateWorksheetWA(attributes, template.id);
      setLoading(false);
      enqueueSnackbar('Fisa de lucru creata cu success', { variant: 'success' });
      rest.setNotify(pc.id);
      rest.cancel();
      openModal(MODALS.TEXT_EDITOR, {
        projectContractId: pc.id,
        canEdit: true,
        viewMode: true,
        title: 'Fisa de lucru'
      });
    } catch (e) {
      setLoading(false);
      let errorMessage = e?.message;
      try {
        errorMessage = JSON.parse(e?.message);
      } catch (er) {
        console.log('Error', er);
      }

      if (errorMessage?.message?.includes('duplicate key value violates unique constraint')) {
        enqueueSnackbar('Nu se poate adauga inainte de primul PVP', { variant: 'error' });
      } else {
        enqueueSnackbar(`Problema adaugare fisa de lucru: ${e?.message}`, { variant: 'error' });
      }
    }
  };

  const loadDependences = useCallback(async () => {
    try {
      setLoadingDependences(true);
      const project = await api.projects.get(extra.projectId);
      const lab = await api.labs.get(project.laboratory?.id);
      const { workstations } = lab;
      const categories = (await api.categories.list()).result?.filter((cat: Category) => cat.forPvp);
      const labCategories = (await api.labCategories.listByLab(`${project.laboratory?.id}?type=worksheet_template`))
        .result;
      const projectProducts = (await api.projectProducts.listForProject(extra?.projectId)).result;
      setDeps({
        categories,
        labCategories,
        pvps: [],
        filteredPvps: [],
        projectProducts,
        workstations,
        project
      });
      setWorkstation(extra.user.workstation);
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.error("Can't load dependences", e);
    } finally {
      setLoadingDependences(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra]);

  useEffect(() => {
    if (!rest.visible) {
      setAttributes({});
      setError({});
      // setProbesError(null);
      setCategory(null);
      setWorkstation(null);
      setTemplate(null);
      setPvp(null);
      // setProducts([]);
      // setSelectedProducts([]);
      setProbe([]);
      setProbeList([]);
      setProbeListReported([]);
      setProbeListUntested([]);
      setProbeListUnreceived([]);
      setSelectedProbes([]);
    } else {
      loadDependences();
    }
  }, [rest.visible, loadDependences]);

  const changeCategory = async (catId: string) => {
    const cat = deps?.categories.find((p) => p.id === catId) || null;
    if (cat) {
      const labCats = deps?.labCategories.filter((lc) => lc.category.id === cat.id);
      if (labCats.length) {
        const newTemplates = labCats.map((lc) => lc.projectContract);
        setTemplates(newTemplates);
        if (newTemplates.length === 1) {
          setTemplate(newTemplates[0]);
        } else {
          setTemplate(null);
        }
      } else {
        setTemplates([]);
        setTemplate(null);
      }
      const pvpsProject = (await api.projectContracts.listPVPs(extra?.projectId)).result?.filter((pv) => !pv.canceled);
      let filteredPvps = [...pvpsProject];
      if (filters.indexOf('category') >= 0)
        filteredPvps = filteredPvps.filter((p) => p.contract?.docAttributes['extra.categoryId'] === cat.id);
      setDeps({ ...deps, pvps: pvpsProject, filteredPvps });
      setCategory(cat);
    }
  };

  const handleChangeCategory = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeCategory(event.target.value);
  };

  const handleChangeTemplate = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const selectedTemplate = templates.find((t) => t.id === event.target.value) || null;
    setTemplate(selectedTemplate);
  };

  const cleanUpProbeAttributes = () => {
    const newAttributes = { ...attributes };
    Object.keys(attributes).forEach((key: string) => {
      if (key.startsWith('probe.')) {
        delete newAttributes[key];
      }
    });
    setAttributes(newAttributes);
  };

  useEffect(() => {
    if (
      rest.visible &&
      !category &&
      !pvp &&
      deps.categories.length &&
      deps.labCategories.length &&
      deps.pvps.length &&
      !loadingDependences
    ) {
      if (extra.categoryId) {
        if (extra?.categoryId) {
          changeCategory(extra.categoryId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.visible, deps, loadingDependences]);

  const handleChangePvps = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selected: ProjectContract[]
  ) => {
    setLoading(true);
    const pids = selected.map((p) => p.id);
    setPvpIds(selected.map((p) => p.id));
    setPvps(selected);
    let probes = [];
    if (selected.length) probes = (await api.probes.listPg({ criteria: { pvpIds: pids.join(',') } })).result;
    setProbe(probes);
    setError({ ...error, probe: false });
    const probesReported: Probe[] = [];
    const probesUntested: Probe[] = [];
    const probesUnreceived: Probe[] = [];
    const probesNormal: Probe[] = [];

    probes.forEach((p) => {
      if (p.status === PROBE_STATUS.NEW) {
        probesUnreceived.push(p);
      } else if (p.status === PROBE_STATUS.FINAL) {
        probesReported.push(p);
      } else if (p.status === PROBE_STATUS.READY) {
        probesNormal.push(p);
      } else {
        probesUntested.push(p);
      }
    });
    setProbeListReported(probesReported);
    setProbeListUntested(probesUntested);
    setProbeListUnreceived(probesUnreceived);
    setProbeList(probesNormal);
    setSelectedProbes([]);
    cleanUpProbeAttributes();
    setLoading(false);
  };

  const resetError = (data: DocumentAttributes) => {
    const newError = { ...error };
    Object.keys(data).forEach((key: string) => {
      if (newError[key]) newError[key] = false;
    });
    setError(newError);
  };

  const handleChangeData = (data: DocumentAttributes) => {
    resetError(data);
    setAttributes(data);
  };

  const handleChangeProbes = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value as unknown;
    let newSelectedProbes = [...selectedProbes];
    if (newSelectedProbes.find((sp) => sp === value)) {
      newSelectedProbes = selectedProbes.filter((sp) => sp !== value);
    } else {
      newSelectedProbes.push(value as string);
    }
    setSelectedProbes(newSelectedProbes);
    const newAttributes = { ...attributes };
    Object.keys(attributes).forEach((key: string) => {
      if (key.startsWith('probe.')) delete newAttributes[key];
    });
    const pa = newSelectedProbes.map((pno, index) => {
      const no = index + 1;
      const id = probe.find((p) => p.pno === pno)?.id;
      newAttributes[`probe.${no}.pno`] = pno;
      newAttributes[`probe.${no}.id`] = id;
      return { no, pno, id };
    });
    setProbeAddins(pa);
    setAttributes(newAttributes);
    setError({ ...error, probe: false });
  };

  const handleChangeWorkstation = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const work = deps?.workstations.find((w) => w.id === event.target.value) || null;
    setWorkstation(work);
    if (error?.workstation) setError({ ...error, workstation: false });
  };

  const handleChangeFilters = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    const newFilters = [...filters];
    const index = newFilters.indexOf(value);
    if (index > -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(value);
    }
    setFilters(newFilters);
    if (newFilters.indexOf('category') >= 0) {
      const filteredPvps = deps.pvps?.filter((p) => p.contract?.docAttributes['extra.categoryId'] === category.id);
      setDeps({ ...deps, filteredPvps });
    } else {
      setDeps({ ...deps, filteredPvps: deps.pvps });
    }
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga fisa de lucru"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {loadingDependences ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <TextField
                  fullWidth
                  label="Punct de lucru"
                  onChange={handleChangeWorkstation}
                  value={workstation?.id}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  error={error?.workstation}
                  disabled
                  InputLabelProps={{ shrink: !!workstation?.id }}
                  helperText={error?.workstation && 'Punctul de lucru este obligatoriu!'}
                >
                  <option key="" value="" />
                  {deps?.workstations &&
                    deps.workstations.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              {loadingDependences ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <>
                  <TextField
                    fullWidth
                    label="Profil"
                    onChange={handleChangeCategory}
                    value={category?.id}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    InputLabelProps={{ shrink: !!category?.id }}
                  >
                    <option key="" value="" />
                    {deps?.categories &&
                      deps.categories.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                  {!!category && !templates.length && (
                    <Box>
                      <FormHelperText error>Acest profil nu are un template definit!</FormHelperText>
                    </Box>
                  )}
                </>
              )}
            </Grid>
            {templates.length ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Template"
                  onChange={handleChangeTemplate}
                  value={template?.id || ''}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: !!template?.id }}
                  disabled={templates.length === 1}
                >
                  <option key="" value="" />
                  {templates.map((pc: ProjectContract) => (
                    <option key={pc.id} value={pc.id}>
                      {pc.contract.title}
                    </option>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {template && !loadingDependences && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filters.indexOf('category') >= 0}
                        onChange={handleChangeFilters}
                        value="category"
                      />
                    }
                    label="Afiseaza PV-uri din profil"
                  />
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={deps.filteredPvps}
                    getOptionLabel={(option) => option.pno}
                    // defaultValue={[]}
                    onChange={handleChangePvps}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selecteaza PV-uri"
                        placeholder="Cauta dupa nr."
                      />
                    )}
                  />
                </Grid>
                {!!pvps.length && (
                  <Grid item xs={12}>
                    {!!probeList.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe eligibile</FormHelperText>
                        {probeList.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                value={p.pno}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!!probeListUnreceived.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe ne-receptionate</FormHelperText>
                        {probeListUnreceived.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={p.pno}
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!!probeListUntested.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe nelucrate</FormHelperText>
                        {probeListUntested.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={p.pno}
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!!probeListReported.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe incluse in alte rapoarte</FormHelperText>
                        {probeListReported.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={p.pno}
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!probe.length && !loading && (
                      <Box>
                        <FormHelperText error className={classes.noProbe}>
                          Nu exista probe disponibile!
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>
                )}
                {!!selectedProbes.length &&
                  probeAddins.map((p) => (
                    <React.Fragment key={p.no}>
                      <DocumentForm
                        title={`Proba #${p.pno}`}
                        data={attributes}
                        tempAttributes={template.contract.tempAttributes}
                        onChange={handleChangeData}
                        dataKey="probe."
                        index={p.no}
                      />
                    </React.Fragment>
                  ))}
                {!!pvps.length && !!selectedProbes.length && !loading && (
                  <>
                    <Grid item xs={12}>
                      <Divider style={{ marginBottom: 30 }} />
                      <Typography variant="h4" color="textPrimary">
                        Campuri fisa de lucru
                      </Typography>
                    </Grid>
                    <DocumentForm
                      data={attributes}
                      error={error}
                      requiredFields={REPORT_MANDATORY_FIELDS}
                      tempAttributes={template.contract.tempAttributes}
                      onChange={handleChangeData}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={saveHandler}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={!template || !pvps.length || !probe.length || !selectedProbes.length}
            loading={loading}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default ProjectWorksheetCreateModal;
