import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme } from 'src/theme';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';

interface DialogAdvancedProps {
  visible: boolean;
  title?: string;
  children: JSX.Element;
  actionTitle?: string;
  dialogContent?: boolean;
  cancel: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  action?: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => unknown;
  setNotify?: (data?) => void;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  mode?: 'general' | 'delete';
  contentClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  cancel: {
    color: theme.palette.primary.main
  },
  action: {
    color: theme.palette.error.main
  },
  primary: {
    color: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.action.active
  },
  error: {
    color: theme.palette.primary.main
  },
  pending: {
    width: 93,
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const DialogAdvanced: FC<DialogAdvancedProps> = ({
  visible,
  title,
  children,
  actionTitle,
  dialogContent,
  cancel,
  action,
  setNotify,
  maxWidth,
  mode = 'delete',
  contentClassName = ''
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [actionPending, setActionPending] = useState(false);

  // Use this if you want to have controls on modal;
  const customAction = async (event) => {
    setActionPending(true);
    try {
      const item = await action(event);
      if (setNotify) setNotify(item ? item : new Date().getTime());
      if (item) {
        enqueueSnackbar(item, { variant: 'success' });
      }
      close({ event: {} });
    } catch (e) {
      enqueueSnackbar(`Eroare salvare: ${e.message}`, {
        variant: 'error'
      });
    }
    setActionPending(false);
  };

  const close = (event) => {
    if (setNotify) setTimeout(() => setNotify(null), 100);
    cancel(event);
  };

  return (
    <Dialog
      open={visible}
      onClose={close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{ style: { overflow: 'hidden' } }}
      fullWidth
      maxWidth={maxWidth || 'md'}
      disableEnforceFocus
      // scroll="paper"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {dialogContent ? <DialogContent className={clsx(contentClassName)}>{children}</DialogContent> : children}
      </PerfectScrollbar>
      {action && (
        <DialogActions>
          <Button
            onClick={close}
            startIcon={<CloseIcon />}
            className={mode === 'general' ? classes.secondary : classes.primary}
          >
            Anuleaza
          </Button>
          {actionPending ? (
            <Box className={classes.pending}>
              <CircularProgress size={24} className={mode === 'general' ? classes.primary : classes.error} />
            </Box>
          ) : (
            <Button
              onClick={customAction}
              startIcon={mode === 'general' ? <SaveIcon /> : <DeleteIcon />}
              className={mode === 'general' ? classes.primary : classes.error}
            >
              {actionTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogAdvanced;
