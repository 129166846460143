import { ATTACHMENT_TYPE, UploadedFile } from 'src/types/file';

const dataURLtoFile = ({ dataUrl = '', filename = 'file.png', attachmentType = ATTACHMENT_TYPE.USER_SIGNATURE }) => {
  if (!dataUrl) return null;
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new UploadedFile([u8arr], filename, { type: mime });
  file.attachmentType = attachmentType;
  return file;
};
export default dataURLtoFile;
